import React from 'react';
import { graphql, Link } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Hexagon from '../components/Hexagon';
import styled from 'styled-components';
import { GitPullRequest } from 'react-feather';
import Mobile from '../images/mobile.inline.svg';
import Connection from '../images/connection-icon.inline.svg';
import Flow from '../images/flow-icon.inline.svg';
import Coding from '../images/coding-icon.inline.svg';
import Video from '../images/video-icon.inline.svg';
import PageSection from '../components/PageSection';
import Colors from '../constants/Colors';

const HomepageHero = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const iconStyle = {
  width: 100,
  height: 100,
  margin: 10,
};

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="home"
        keywords={[`devblog`, `javascript`, `react`, `react-native`, `expo`]}
      />
      <HomepageHero>
        <div
          style={{ display: 'flex', flexFlow: 'column', alignItems: 'center' }}
        >
          <p>When single night mode just won't do.</p>
          <Hexagon>
            <GitPullRequest size={48} style={{ zIndex: 4 }} />
          </Hexagon>
        </div>
      </HomepageHero>

      <PageSection color="pink">
        <h2>Whaaaaa?</h2>
        <p>a software company of sorts, more or less.</p>
      </PageSection>
      <PageSection color="blue">
        <h2>Whyyyy?</h2>
        <p>because making stuff!</p>
        {/* <AniLink cover bg={Colors.green} to="/projects">
          see the stuff
        </AniLink> */}
      </PageSection>
      <PageSection color="yellow">
        <h2>Fancy icons?</h2>
        <p>we got 'em!</p>

        <IconsContainer>
          <Mobile style={iconStyle} />
          <Connection style={iconStyle} />
          <Flow style={iconStyle} />
          <Coding style={iconStyle} />
          <Video style={iconStyle} />
        </IconsContainer>
      </PageSection>
      <PageSection color="green">
        <h2>Interested?</h2>
        <AniLink cover bg={Colors.green} to="/contact">
          Contact us
        </AniLink>
      </PageSection>
      <PageSection color="pink">
        <h2>Blog?</h2>
        <AniLink cover bg={Colors.green} to="/blog">
          <span>yep, blog</span>
        </AniLink>
      </PageSection>
    </Layout>
  );
};
export default IndexPage;
