import * as React from 'react';
import styled from 'styled-components';
import Colors from '../constants/Colors';

const StyledPageSection = styled.div`
  width: 100%;
  padding: 50px 20px;
  margin: 20px 0;
  border: 4px solid ${({ color }) => Colors[color] ?? Colors.pink};
  border-radius: 5px;
`;

export default function PageSection({ children, color }) {
  return <StyledPageSection color={color}>{children}</StyledPageSection>;
}
